import { RenderByPermission } from '@/utils';
import { Button, Drawer, Form, Modal, Space } from 'antd';
import { runInAction } from 'mobx';
import { Provider, inject, observer } from 'mobx-react';
import React from 'react';
import CompanyEditorModal from '../../../employer/companyInfo/companyEditor/index.tsx';
import type store from '../../model.tsx';
import AccountInformation from './accountInformation/index.tsx';
import CompanyInfo from './companyInfo/index.tsx';
import ExperienceRequirement from './experienceRequirement/index.tsx';
import styles from './index.less';
import JobInformation from './jobInformation/index.tsx';
import JobRecommendation from './jobRecommendation/index.tsx';
import JobRequirements from './jobRequirements/index.tsx';
import LiveBroadcasting from './liveBroadcasting/index.tsx';
import NewJob from './newJob/index.tsx';
import PastExperience from './pastExperience/index.tsx';
import PositionAllocation from './positionAllocation/index.tsx';
import SalaryAndTreatment from './salaryAndTreatment/index.tsx';
import SpecialRequirements from './specialRequirements/index.tsx';
import TypesettingForm from './typesettingForm/index.tsx';
import WelfareTreatment from './welfareTreatment/index.tsx';
import WorkingHours from './workingHours/index.tsx';

@inject('store')
@observer
export default class AddDrawer extends React.Component<{ store?: store }> {
  render() {
    const pageId = new URLSearchParams(window.location.search)?.get('pageId');

    const {
      open,
      formRef,
      jobType,
      type,
      getPositionDetailById,
      savePosition,
      onClose,
      setValue,
      postJob,
      status,
      isModified,
      id,
      companyEditorModalModel,
      parentStore,
    } = this.props.store.addModel;
    return (
      <>
        <CompanyEditorModal store={companyEditorModalModel} />
        <Drawer
          forceRender={true}
          closeIcon={false}
          width={'100%'}
          open={open}
          className={styles.drawer}
        >
          <div className={'flex flex-col h-full overflow-hidden !bg-[#F0F3F8]'}>
            <Provider store={this.props.store.addModel}>
              <Form
                disabled={type === 2}
                scrollToFirstError={true}
                onValuesChange={() => {
                  setValue('isModified', true);
                }}
                className={'flex overflow-y-auto flex-1 flex-col pb-[8px]'}
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 12 }}
                ref={formRef}
              >
                <Space direction={'vertical'}>
                  <NewJob />
                  {id && [2, 3].includes(type) ? <JobRecommendation /> : undefined}
                  <CompanyInfo />
                  <JobInformation />
                  <ExperienceRequirement />
                  <WorkingHours />
                  <SalaryAndTreatment />
                  <JobRequirements />
                  <AccountInformation />
                  <PositionAllocation />
                  {[1].includes(jobType) ? <TypesettingForm /> : undefined}
                  <LiveBroadcasting />
                  <PastExperience />
                  <WelfareTreatment />
                  <SpecialRequirements />
                </Space>
              </Form>
            </Provider>
            <Space className={'flex-shrink-0 flex flex-row items-center justify-end bg-white py-3 pr-[8px]'}>
              {[1, 2, 3].includes(type) ? (
                <Button
                  onClick={() => {
                    if (isModified && type !== 2) {
                      Modal.confirm({
                        title: '有未保存的信息,是否确认关闭页面',
                        onOk: () => {
                          return onClose();
                        },
                        onClose: () => {},
                      });
                      return;
                    }
                    onClose();
                  }}
                >
                  关闭
                </Button>
              ) : undefined}
              {type === 2 && [2, 3, 4].includes(status) ? (
                <RenderByPermission permissionId={`${pageId}_4`}>
                  <Button
                    onClick={() => {
                      parentStore.onDelete(id, () => {
                        onClose();
                      });
                    }}
                  >
                    删除
                  </Button>
                </RenderByPermission>
              ) : undefined}
              {type === 2 && [3, 4].includes(status) ? (
                <RenderByPermission permissionId={`${pageId}_14`}>
                  <Button
                    type="primary"
                    onClick={() => {
                      runInAction(() => {
                        parentStore.temporaryId = id;
                        parentStore.auditPositionOpen = true;
                        parentStore.auditPositionCallback = () => {
                          getPositionDetailById();
                        };
                      });
                    }}
                  >
                    人工审核
                  </Button>
                </RenderByPermission>
              ) : undefined}
              {type === 2 && [2].includes(status) ? (
                <RenderByPermission permissionId={`${pageId}_12`}>
                  <Button
                    type="primary"
                    onClick={() => {
                      parentStore.onOrOffPosition({
                        id: id,
                        status: 2,
                        callback: () => {
                          getPositionDetailById();
                        },
                      });
                    }}
                  >
                    上线
                  </Button>
                </RenderByPermission>
              ) : undefined}
              {type === 2 && [1].includes(status) ? (
                <RenderByPermission permissionId={`${pageId}_12`}>
                  <Button
                    type="primary"
                    onClick={() => {
                      parentStore.onOrOffPosition({
                        id: id,
                        status: 1,
                        callback: () => {
                          getPositionDetailById();
                        },
                      });
                    }}
                  >
                    下线
                  </Button>
                </RenderByPermission>
              ) : undefined}
              {type === 2 && [1].includes(status) ? (
                <RenderByPermission permissionId={`${pageId}_71`}>
                  <Button
                    type="primary"
                    onClick={() => {
                      parentStore.recommendedPositionModel.onOpen([id] as string[]);
                    }}
                  >
                    推荐
                  </Button>
                </RenderByPermission>
              ) : undefined}
              {type === 2 ? (
                <RenderByPermission permissionId={`${pageId}_3`}>
                  <Button
                    type={'primary'}
                    onClick={() => {
                      runInAction(() => {
                        setValue('type', 3);
                      });
                    }}
                  >
                    编辑
                  </Button>
                </RenderByPermission>
              ) : undefined}

              {[1, 2, 3].includes(type) ? (
                <Button
                  onClick={() => {
                    savePosition();
                  }}
                  style={{ backgroundColor: 'rgba(58, 110, 248, 0.10)' }}
                  className={'border-[#3A6EF8] text-[#3A6EF8]'}
                >
                  保存
                </Button>
              ) : undefined}
              {[1, 3].includes(type) ? (
                <Button
                  onClick={() => {
                    postJob();
                  }}
                  type={'primary'}
                >
                  上线
                </Button>
              ) : undefined}
            </Space>
          </div>
        </Drawer>
      </>
    );
  }
}
