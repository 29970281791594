import { Card, Checkbox, Form, Input, Radio, Space } from 'antd';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { acceptNewbieDict } from '../dict.ts';
import styles from '../index.less';
import type AddModel from '../model.tsx';

/**
 * 职位推荐
 */
@inject('store')
@observer
class JobRecommendation extends React.Component<{ store?: AddModel }> {
  render() {
    const {
      parentStore: { categoryList },
    } = this.props.store;
    return (
      <Card
        title="职位推荐"
        className={styles.card}
      >
        <Space
          size={[20, 16]}
          className={'w-full'}
          direction={'vertical'}
        >
          {categoryList
            ? Object.keys(categoryList)
                .map((key) => {
                  if (categoryList[key]?.list?.length > 0) {
                    return (
                      <Form.Item
                        key={key}
                        label={categoryList[key]?.categoryName}
                        name={key}
                        className={'mb-0'}
                      >
                        <Checkbox.Group
                          disabled={false}
                          options={categoryList[key]?.list}
                        />
                      </Form.Item>
                    );
                  }
                  return undefined;
                })
                .filter((item) => item)
            : undefined}
          <Form.Item
            label="推荐话术"
            name={'brokerRecommend'}
            className={'mb-0'}
          >
            <Input
              autoComplete="off"
              disabled={false}
              placeholder={'请输入推荐话术'}
            />
          </Form.Item>
        </Space>
      </Card>
    );
  }
}
export default JobRecommendation;
